import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   // path: '/index.html',
  //   name: 'welcome',
  //   component: () => import('../views/welcome.vue')
  //   // component: () => import('../views/Index20.vue')
  // },
  // {
  //   path: '/',
  //   redirect: '/index.html'
  // },
  {
    path: '/',
    redirect: '/picture.html'
  },
  {
    path: '/picture.html',
    // component: () => import('../views/Index.vue')
    // component: () => import('../views/Index20.vue')
    component: () => import('../views/Index2.vue')
  },
  {
    path: '/post.html',
    component: () => import('../views/postDetail.vue')
  },
  {
    path: '/catalog.html',
    component: () => import('../views/catalog.vue')
  },
  {
    path: '/gyCatalog.html',
    component: () => import('../views/gyCatalog.vue')
  },
  {
    path: '/videoCatalog.html',
    component: () => import('../views/videoCatalog.vue')
  },
  {
    path: '/index.html',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/checkLogin.html',
    component: () => import('../views/checkLogin.vue')
  },
  {
    path: '/sjjscatalog.html',
    component: () => import('../views/sjjscatalog.vue')
  },
  {
    path: '/activityPage.html',
    component: () => import('../views/activityPage.vue')
  },
  {
    path: '/upPost.html',
    component: () => import('../views/upPost.vue')
  },
  {
    path: '/upPostRich.html',
    component: () => import('../views/upPostRich.vue')
  },
  {
    path: '/myInfo.html',
    component: () => import('../views/myInfo.vue')
  },
  {
    path: '/myPost.html',
    component: () => import('../views/myPost.vue')
  },
  {
    path: '/bindMobile.html',
    component: () => import('../views/bindMobile.vue')
  },
  {
    path: '/bindShopChange.html',
    component: () => import('../views/bindShopChange.vue')
  },
  {
    path: '/userShow.html',
    component: () => import('../views/userShow.vue')
  },
  {
    path: '/userPostList.html',
    component: () => import('../views/userPostList.vue')
  },
  {
    path: '/postEdit.html',
    component: () => import('../views/postEdit.vue')
  },
  {
    path: '/updataInfo.html',
    component: () => import('../views/updataInfo.vue')
  },
  {
    path: '/resetPassword.html',
    component: () => import('../views/resetPassword.vue')
  },
  {
    path: '/userShowList.html',
    component: () => import('../views/userShowList.vue')
  },
  {
    path: '/userShowDetail.html',
    component: () => import('../views/userShowDetail.vue')
  },
  {
    path: '/gongGao.html',
    component: () => import('../views/gongGao.vue')
  },
  {
    path: '/local.html',
    component: () => import('../views/local.vue')
  },
  {
    path: '/xieyi.html',
    component: () => import('../views/xieyi.vue')
  },
  {
    path: '/fixTopic.html',
    component: () => import('../views/fixTopic.vue')
  },
  {
    path: '/sjjsfixTopic.html',
    component: () => import('../views/sjjsFixtopic.vue')
  },
  {
    path: '/buy.html',
    component: () => import('../views/buy.vue')
  },
  {
    path: '/qiancun.html',
    component: () => import('../views/qiancun.vue')
  },
  {
    path: '/qiancunqishi.html',
    component: () => import('../views/qiancunqishi.vue')
  },
  {
    path: '/qiancunPost.html',
    component: () => import('../views/qiancunPost.vue')
  },
  {
    path: '/qishit.html',
    component: () => import('../views/qishi.vue')
  },
  {
    path: '/alreadyBuy.html',
    component: () => import('../views/alreadyBuy.vue')
  },
  {
    path: '/shopCar.html',
    component: () => import('../views/shopCar.vue')
  },
  {
    path: '/myOrder.html',
    component: () => import('../views/myOrder.vue')
  },
  {
    path: '/activity.html',
    component: () => import('../views/activity.vue')
  },
  {
    path: '/advertisement.html',
    component: () => import('../views/advertisement.vue')
  },
  {
    path: '/register.html',
    component: () => import('../views/register.vue')
  },
  {
    path: '/search.html',
    component: () => import('../views/search.vue')
  },
  {
    path: '/announceDetail.html',
    component: () => import('../views/announceDetail.vue')
  },
  {
    path: '/forgetPassWord.html',
    component: () => import('../views/forgetPassWord.vue')
  },
  {
    path: '/picSearch.html',
    component: () => import('../views/picSearch.vue')
  },
  {
    path: '/myVideo.html',
    component: () => import('../views/video/myvideo.vue')
  },
  {
    path: '/uploadVideo.html',
    component: () => import('../views/video/uploadvideo.vue')
  },
  // videoSearch
  {
    path: '/videoSearch.html',
    component: () => import('../views/video/videoSearch.vue')
  },
  {
    path: '/video.html',
    redirect: '/picture.html'
  },
  {
    path: '/video.html',
    component: () => import('../views/video/index.vue')
  },
  {
    path: '/videoFixtopic.html',
    component: () => import('../views/video/videoFixtopic.vue')
  },
  {
    path: '/videoDetail.html',
    component: () => import('../views/video/videoDetail.vue')
  },
  {
    path: '/mljs2021.html',
    component: () => import('../views/mljs.vue')
  },
  {
    path: '/vote.html',
    component: () => import('../views/vote.vue')
  },
  {
    path: '/woxinzhongdebangyang.html',
    component: () => import('../views/woxinzhongdebangyang.vue')
  },
  // zhishichanquanvideo
  {
    path: '/zhishichanquanvideo.html',
    component: () => import('../views/zhishichanquanvideo.vue')
  },
  {
    path: '/activityNew.html',
    component: () => import('../views/activityNew.vue')
  },
  {
    path: '/guoan.html',
    component: () => import('../views/guoan.vue')
  },
  {
    path: '/guoanCopy.html',
    component: () => import('../views/guoanCopy.vue')
  },
  {
    path: '/guoanqishi.html',
    component: () => import('../views/guoanqishi.vue')
  },
  {
    path: '/guoandanwei.html',
    component: () => import('../views/guoandanwei.vue')
  },
  {
    path: '/gaRegister.html',
    component: () => import('../views/gaRegister.vue')
  },
  {
    path: '/guoanPost.html',
    component: () => import('../views/guoanPost.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
